<template>
  <a :href="'/user/'+value?.id" v-if="value">
    <img class="avatar-image" :src="value?.avatar_url" :alt="value?.full_name" />
    <span class="text-gray-dark mx-2">
      {{value?.first_name}} {{value?.last_name}}
    </span>
  </a>
</template>

<script>
export default {
  name: "User",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
